import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'

const HomeProjects = ({ projects }) => {
  const [isDesktop, setIsDesktop] = useState(null)

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsDesktop(window.innerWidth > 1024)
    }
  }, [])

  const projectssAnim = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 500,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  return (
    <WrapProjects style={projectssAnim}>
      {projects.map(projectItem => (
        <WrapItem
          key={projectItem.node.uid}
          style={{
            marginLeft: isDesktop
              ? `${projectItem.node.data.margin_left_desktop}%`
              : `${projectItem.node.data.margin_left_mobile}%`,
            width: isDesktop
              ? `${projectItem.node.data.width_desktop}%`
              : `${projectItem.node.data.width_mobile}%`
          }}
        >
          <ProjectItem to={`/projects/${projectItem.node.uid}`}>
            <Image fluid={projectItem.node.data.image.localFile.childImageSharp.fluid} fadeIn />
            <ProjectTitle>{projectItem.node.data.title.text}</ProjectTitle>
          </ProjectItem>
        </WrapItem>
      ))}
    </WrapProjects>
  )
}

const WrapProjects = styled(animated.section)`
  width: 100%;
  top: 50%;
  left: 50%;
  margin-top: 66vh;
  margin-bottom: 50px;

  @media screen and (max-width: 500px) {
    width: 100%;
    max-height: 100%;
    top: 50%;
    left: 50%;
  }
`

const WrapItem = styled.div`
  width: 100vw;
  height: auto;
  padding: 50px 0;
`

const Image = styled(Img)`
  width: 100%;
  margin-bottom: 10px;
  transition: opacity 0.15s ease-out;
`

const ProjectItem = styled(Link)`
  h2 {
    display: inline-block;
    border-bottom: 1px solid transparent;
  }
  &:hover {
    h2 {
      /* color: #7a7a7a; */
      border-bottom: 1px solid black;
    }
  }
`

const ProjectTitle = styled.h2`
  font-size: 15px;
  font-weight: 300;
  transition: color 0.15s ease-out;

  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
`

export default HomeProjects
