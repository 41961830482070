import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Notifications from '../components/home/notifications'
import HomeProjects from '../components/home/projects'

export const IndexQuery = graphql`
  query Project {
    project: allPrismicProject(sort: { fields: [data___project_order_number], order: DESC }) {
      edges {
        node {
          uid
          id
          data {
            title {
              text
            }
            margin_left_desktop
            margin_left_mobile
            width_desktop
            width_mobile
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    notifications: allPrismicNotification(
      sort: { fields: [data___notif_order_number], order: DESC }
    ) {
      edges {
        node {
          data {
            end_date(fromNow: false, locale: "")
            start_date
            notif_title {
              text
            }
            text_notif {
              html
              text
            }
            emoji_item {
              text
            }
            color_bg
            color_text
          }
        }
      }
    }
  }
`

const IndexPage = props => (
  <Layout>
    <SEO
      title="Home"
      keywords={[`Artist`, `London`, `Jamie`, `Julien`, `Brown`, `design`, `sculpture`, `collage`]}
    />

    <Notifications notifs={props.data.notifications.edges} />

    <HomeProjects projects={props.data.project.edges} />
  </Layout>
)

export default IndexPage
