import React, { useState } from 'react'
import { useSpring, animated } from 'react-spring'
import styled from 'styled-components'

const Notif = ({ notif }) => {
  const [visible, setVisible] = useState(true)

  const closeAnimation = useSpring({
    to: async next => {
      await next({ opacity: visible ? 1 : 0 })
      await next({ height: visible ? 100 : 0 })
    },
    from: { opacity: 1, height: 100 },
    config: { mass: 1, tension: 140, friction: 20 }
  })

  return (
    <Message style={closeAnimation}>
      <Content
        style={{
          backgroundColor: notif.node.data.color_bg,
          color: notif.node.data.color_text
        }}
      >
        <h5 style={{ color: notif.node.data.color_text }}>
          <span>{notif.node.data.emoji_item.text}</span>
          {/* <b>
            {notif.node.data.end_date} - {notif.node.data.start_date}
          </b> */}
          <b>{notif.node.data.notif_title.text}</b>
        </h5>
        <div dangerouslySetInnerHTML={{ __html: notif.node.data.text_notif.html }} />
        {/* <p> {notif.node.data.text_notif.text} </p> */}
        <Button
          onClick={event => {
            setVisible(false)
          }}
        >
          <svg
            width="10"
            height="10"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.41421 1L6 5.58579M6 5.58579L11 10.5858M6 5.58579L1 10.5858M6 5.58579L10.5858 1"
              stroke={notif.node.data.color_text}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Button>
      </Content>
    </Message>
  )
}

const Message = styled(animated.div)`
  position: relative;
  pointer-events: all;
  width: 350px;
  z-index: 1000;

  @media (max-width: 680px) {
    width: 100%;
  }
`

const Content = styled.div`
  opacity: 1;
  padding: 15px 15px;
  font-size: 12px;
  overflow: hidden;
  height: auto;
  border-radius: 5px;

  h5 {
    display: block;
    font-size: 14px;
    line-height: 1.2;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 0;

    span {
      font-size: 14px;
      margin-right: 2px;
    }
  }

  p {
    margin: 0;
    line-height: 1.44;
    max-width: 95%;

    @media (max-width: 680px) {
      max-width: 95%;
    }
  }

  a {
    color: inherit;
    text-decoration: underline;
    /* font-weight: 600; */
    cursor: pointer !important;
    /* pointer-events: all; */
  }
`

const Button = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  pointer-events: all;
  outline: 0;
  border: none;
  background: transparent;
  overflow: hidden;
  margin: 0;
  padding: 0;
  padding-bottom: 14px;
  color: rgba(255, 255, 255, 0.5);
  :hover {
    color: rgba(255, 255, 255, 1);
  }
`

export default Notif
