import React from 'react'
// import { useSpring, animated } from 'react-spring'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import Notif from './notif'

const Notifications = ({ notifs }) => {
  const notifsAnim = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 100,
    config: { mass: 5, tension: 500, friction: 50 }
  })

  return (
    <Container style={notifsAnim}>
      {notifs.map((notifItem, index) => (
        <Notif key={index} notif={notifItem} />
      ))}
    </Container>
  )
}

const Container = styled(animated.div)`
  position: absolute;
  z-index: 10;
  pointer-events: none;
  /* width: 0 auto; */
  top: 30px;
  bottom: ${props => (props.top ? 'unset' : '30px')};
  margin: 0 auto;
  left: 30px;
  right: 25px;
  transition: all 0.5s;
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${props => (props.top ? 'column-reverse' : 'column')};
  /* pointer-events: none; */
  align-items: ${props =>
    props.position === 'center' ? 'center' : `flex-${props.position || 'end'}`};
  @media (max-width: 680px) {
    align-items: center;
    left: 20px;
    right: 20px;
    top: 20px;
  }
`

export default Notifications
